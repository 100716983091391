import type { FeatureTour } from '../types/tour';

const ENDPOINT = '/api/manage-talents/tour';

const completedTours = ref<FeatureTour[]>([]);
const BETA_TOUR_ID = 1;

const { add } = useSnackbar();
const { t } = useLang();
const disableTour = ref<boolean>(false);

export const useTour = () => {
    const fetchTours = async () => {
        const result = await fetchOrRedirect<FeatureTour[]>(
            `${ENDPOINT}/list-completed`,
        );
        if (result.data?.length) {
            result.data.forEach((elem) =>
                completedTours.value.push({ tourId: elem.tourId }),
            );
        }
    };

    const findCompletedTourById = (id: number): FeatureTour | undefined => {
        if (completedTours.value) {
            return completedTours.value.find((tour) => tour.tourId == id);
        }
        return undefined;
    };

    const hasTourCompleted = async (id: number) => {
        const result = await fetchOrRedirect<FeatureTour[]>(
            `${ENDPOINT}/list-completed`,
        );
        let hasCompleted: boolean = false;

        if (result.error) {
            add({
                message: t('notification', 'tourLoadingError'),
                type: 'Error',
            });
            hasCompleted = true;
        }
        if (result.data?.length) {
            result.data.forEach((elem) =>
                completedTours.value.push({ tourId: elem.tourId }),
            );
            hasCompleted = findCompletedTourById(id) != undefined;
        }
        return hasCompleted;
    };

    const rejectTour = async (id: number) => {
        await fetchStatusOrRedirect(`${ENDPOINT}/reject`, {
            body: { tourId: id },
            method: 'POST',
        });
        await fetchTours();
    };

    const finishTour = async (id: number) => {
        await fetchStatusOrRedirect(`${ENDPOINT}/finish`, {
            body: { tourId: id },
            method: 'POST',
        });
        await fetchTours();
    };

    return {
        BETA_TOUR_ID,
        disableTour,
        findCompletedTourById,
        finishTour,
        hasTourCompleted,
        rejectTour,
    };
};
